export const getIsTagVisible = domain => {
	const hardcodeSchoolList = [
		'https://openclass.lifecoach.com.tw',
		'https://taiwaniacapital.teaches.cc',
		'https://t-learning.teaches.cc',
	];
	const isVisible = hardcodeSchoolList.indexOf(domain) === -1;
	return isVisible;
};

export const getIsUnlimitedDaysVisible = domain => {
	const hardcodeSchoolList = [
		'https://openclass.lifecoach.com.tw',
	];
	const isVisible = hardcodeSchoolList.indexOf(domain) === -1;
	return isVisible;
};

export const getIsPriceVisible = eventId => {
	const hardcodeEventIdList = [
		'01FXMGP0BR1XHWXWXNVWS8VHAX', // 當責內部講師認證課程
	];
	const isVisible = hardcodeEventIdList.indexOf(eventId) === -1;
	return isVisible;
};

export const getIsOnlineKeywordVisible = domain => {
	const hardcodeSchoolList = [
		'https://openclass.lifecoach.com.tw',
	];
	const isVisible = hardcodeSchoolList.indexOf(domain) === -1;
	return isVisible;
};

export const getIsCountdownVisible = shortLink => {
	const hardcodeCourseShortLinkList = [
		'etZFWZyu', // 線上直播 - 戲子進修部｜十月課程（單堂報名）
	];
	const isVisible = hardcodeCourseShortLinkList.indexOf(shortLink) === -1;
	return isVisible;
};

export const getIsTeacherFilterVisible = domain => {
	const hardcodeSchoolList = [
		'https://taiwaniacapital.teaches.cc',
	];
	const isVisible = hardcodeSchoolList.indexOf(domain) === -1;
	return isVisible;
};

export const ssoLoginUrl = domain => {
	const hardcodeSchoolList = {
		'https://rfa.teaches.cc': 'https://rfa.pension.org.tw/profile/learning/login',
		'https://rfatest.teaches.cc': 'https://rfa.newbyt.es/profile/learning/login',
		'https://rfa.staging.teaches.cc': 'https://rfa.newbyt.es/profile/learning/login',
		'https://rfa3.dev.teaches.cc': 'https://rfa.newbyt.es/profile/learning/login',
		'https://dan.dev.teaches.cc': 'https://rfa.newbyt.es/profile/learning/login',
		'http://localhost:3002': 'https://rfa.newbyt.es/profile/learning/login',
	};
	return hardcodeSchoolList[domain];
};

export const replaceText = domain => {
	const hardcodeSchoolList = {
		'https://2023tao.teaches.cc': [
			['請輸入折扣碼', '請輸入折扣碼(非學術抵用券)'],
		],
		'https://automan.collegeplus.tw': [
			['學習地圖', '組合課程'],
		],
		'https://www.collegeplus.tw': [
			['學習地圖', '組合課程'],
		],
		'https://dan.dev.teaches.cc': [
			['學習地圖', '組合課程'],
			['請輸入折扣碼', '請輸入折扣碼(非學術抵用券)'],
		],
		'http://localhost:3002': [
			['學習地圖', '組合課程'],
			['請輸入折扣碼', '請輸入折扣碼 (非學術抵用券)'],
		],
		'https://1500soundacademy.teaches.cc': [
			['© Teaches All Rights Reserved.', ''],
			['Powered by Teaches', ''],
		],
	};
	return hardcodeSchoolList[domain];
};

export const footerPowerByVisible = domain => {
	const hardcodeSchoolList = [
		'https://1500soundacademy.teaches.cc',
	];
	const isVisible = hardcodeSchoolList.indexOf(domain) === -1;
	return isVisible;
};

export const coursePriceVisible = domain => {
	const hardcodeSchoolList = [
		'https://www.collegeplus.tw',
	];
	const isVisible = hardcodeSchoolList.indexOf(domain) === -1;
	return isVisible;
};

export const isHardcodeUseECpay = domain => {
	const hardcodeSchoolList = [
		'https://dong34.dev.teaches.cc',
		'https://tao.teaches.cc',
		'https://2023tao.teaches.cc',
	];
	return hardcodeSchoolList.indexOf(domain) !== -1;
};

export const isHardcodeSanwaLogin = school_name => {
	const hardcodeSchoolNameList = [
		'dong45', '三和技研串接測試'
	];
	return hardcodeSchoolNameList.indexOf(school_name) !== -1;
};

export const getLineTagId = domain => {
	const domainCode = {
		'https://everydayhealth.teaches.cc': '7bb037b4-1e9e-44d7-b017-403c04feeb09',
	};
	return domainCode[domain];
};

export const isHardcodeUseCourseCustomerHTML = (id) => {
	const hardcodeCourseList = {
		A6xtBvtw: `
			<table width="100%" cellspacing="0" cellpadding="0">
				<tbody>
					<tr>
						<td align="center">
							<img
								src="http://i.countdownmail.com/2sv65t.gif"
								style="display:inline-block!important;width:100%!important;max-width:272px!important;"
								border="0"
								alt="http://countdownmail.com/"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		`,
		'taiwanbar-6g-readnwrite': `
			<table width="100%" cellspacing="0" cellpadding="0">
				<tbody>
					<tr>
						<td align="center">
							<img
								src="http://i.countdownmail.com/2sv65t.gif"
								style="display:inline-block!important;width:100%!important;max-width:272px!important;"
								border="0"
								alt="http://countdownmail.com/"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		`,
		investigation: `
			<table width="100%" cellspacing="0" cellpadding="0">
				<tbody>
					<tr>
						<td align="center">
							<img
								src="http://i.countdownmail.com/2t9gbz.gif"
								style="display:inline-block!important;width:100%!important;max-width:272px!important;"
								border="0"
								alt="http://countdownmail.com/"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		`,
	};
	return hardcodeCourseList[id];
};

export const getRegisterUrl = domain => {
	const domainCode = {
		'https://ntuseedonline.teaches.cc': 'https://www.surveycake.com/s/qX43k',
	};
	return domainCode[domain];
};

export const getRedirectUrl = domain => {
	const domainCode = {
		'https://taiwanbar.teaches.cc': 'https://school.taiwanbar.cc',
	};
	return domainCode[domain];
};
